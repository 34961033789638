*{
    flex-wrap: wrap;
}

.menu{
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3rem;
    position: relative;
    cursor:pointer;  
}

.listaMenu{
    list-style: none;
    text-decoration: none;  
    color: rgb(255, 255, 255);
    transition: all .2s ease-in-out;
    margin-bottom: 170px;
}
 
.listaMenu li{
    margin-bottom: 1rem;
}

.listaMenu li:hover {
    color: #313131;
    opacity: 3;
}

.img,
.descargaCv{
    text-align: center;
    justify-content: center;
    align-items: center;
}

.img{
    width: 7.5rem;
    margin-bottom: 2rem;
}

@media(min-width: 480px){
    .menu{
        margin-top: 40px;
    }
}

@media(min-width: 992px){
   .menu{
    margin-top: 60px;
   }
}