
.conteinerProyectos{
    font-family: Quicksand, sans-serif;
    margin-top: 100px ;
    display: flex;
    justify-content: center;
}

.conteinerCards{
    width: 20rem;
    margin: 0 0.75rem 1rem 0.75rem;
}

.iconoProyecto{
    display: flex;
    font-size: 1rem;
    padding: 5px 5px 5px 5px;
    height: 25px;
    font-family: Quicksand, sans-serif;
    margin-left: 5px;
    margin-bottom: 5px;
}

.tituloIconoProyecto{
    font-size: 12px;
    margin-left: 0.2rem;
    color: white
}

.iconsWrap{
    justify-content: center;
    display: flex;
}

.iconosLinkProyecto{
    font-size: 2rem;
    text-align: center;
}