* {
    padding: 0%;
    margin: 0%;
}

.cajas {
    background-color: rgb(247, 224, 216);
}



.Menu {
    position: fixed;
    top: 0;
    background-color: #5f52a8;
    width: 180px;
    height: 100vh;
    transition: 250ms;
    z-index: 1;
    box-shadow: inset 4em 2em rgba(145, 119, 180, 0.815);
}

.Menu.active {
    left: 0;
    transition: 350ms;
}

.Home {
    background-color: rgb(247, 224, 216);
    min-height: 100vh;
    overflow: hidden;
    z-index: 0;
    
}

.toggle-menu {
    position: absolute;
    display: inline;
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    background: linear-gradient(rgb(13, 0, 128), rgb(68, 63, 117));
}
.toggle{
    width: 25px;
    height: 20px;
}

.toggle-close{
    position: relative;
    left: 190px;
    color:rgb(158, 156, 156)
}

.toggle-close:hover {
    color: #dad2d2;
    opacity: 1;
}

.toggle:hover {
    color: #c2bfbf;
    opacity: 1;
}

@media(min-width: 480px){
    .Menu{
        left: -100%;
    }
    .Home {
        padding-left: 30px;
        padding-right: 30px;
    }


}

@media(min-width: 768px){
    .Home {
        padding-left: 40px;
        padding-right: 40px;
    }
}


@media(min-width:992px) {
    .Home {
        margin-left: 180px;
        padding-left: 60px;
       padding-right: 60px;
    }

    .Menu{
        left:0;
    }

    .toggle-menu, .toggle-close {
        display: none;
    }
}