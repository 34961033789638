
.conteiner{
   background-color: rgb(228, 167, 167);
   padding: 20px;
}

.text, .iconGit , .iconLink{
   color: white;
}

.text1{
   font-family: 'Playfair Display', serif;
}

.text2{
   font-family: 'Quicksand', sans-serif;
}

.botonesRedes{
   margin-top:2rem;
   text-decoration: none;
}

.iconLink{
   margin-right: 2rem;
}

@media(min-width:480px){
   .conteiner{
      margin-top:70px;
   }

   .fotoContainer{ 
      text-align: center;
      justify-content: center;
      align-items: center;
   }

   .foto {
      margin-left: 1rem;
      width: 18rem;
      height: 19rem;
   }

   .text{
      padding-top: 60px;
      padding-left: 10px;
      text-align: center;
   }

   .text1{
      font-size: 35px;
   }

   .botonesRedes{
      font-size: 2.5rem;
   }
   
}

@media(min-width:768px){
   .conteiner{
      margin-top:80px;
      display: flex;
   }

   .fotoContainer{
      width:45%
   }

   .foto {
      margin-left: 2rem;
   }

   .text{    
       width:50%;
       text-align: end;
       margin-right: 1.5rem;
   }

   .text1{
      font-size: 33px;
   }
}

@media(min-width: 992px){
   .conteiner{
      margin-top:40px;
   }

   .foto {
      width: 20rem;
      height: 21rem;
   }

   .text1{
      font-size: 30px;
   }
}


@media(min-width:1200px){
   .foto {
      margin-left: 4rem;
      width: 24.5rem;
      height: 25.5rem;
   }

   .text{
      padding-top: 50px;
      padding-left: 20px;
   }

   .text1{
      font-size: 60px;
   }
   .text2{
      font-size: 20px;
   }
   .botonesRedes{
      font-size: 3rem;
   }
   
}


